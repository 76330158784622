<section mat-dialog-content class="bg-green h100vh">
  <app-error (tryAgainClicked)="onClick(stepper)"></app-error>
  <div class="content pt50px flow3-1">
    <div class="container-row close-new">
      <button
        mat-icon-button
        [mat-dialog-close]="true"
        (click)="startTimeout()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-stepper
      [linear]="isLinear"
      #stepper
      class="mt55px"
      (selectionChange)="onStepChange($event)"
    >
      <mat-step [completed]="active && isButtonClicked && isUpload">
        <ng-template matStepLabel
          ><span class="label-stepper">Add Metadata</span></ng-template
        >
        <div class="container-row-meta article-container mb30px">
          <div class="card-basic-new w48 mr30px">
            <div class="text14">
              <span class="bold">Step 1</span><br />
              <span>
                Download the metadata template (click button below). Add
                metadata columns and populate the template with your metadata. </span
              ><br />
              <span class="bold">Note:</span> Do not rename the Sample Name,
              Sample Set or Raw File Path columns.
            </div>
            <div class="mt20px">
              <button
                mat-stroked-button
                color="accent"
                (click)="downloadTemplate()"
              >
                Download Metadata Template
              </button>
            </div>
          </div>
          <div class="card-basic-new w48">
            <div class="text14">
              <span class="bold">Step 2</span><br />
              <span>
                Save the metadata template as a CSV or Excel.<br /><br />
              </span>
              <!-- <div>
                <span class="bold">Note:</span> Don't change the Sample Name or
                Sample Set columns!
              </div> -->
            </div>
          </div>
          <div class="column"></div>
        </div>
        <div class="content-upload">
          <div class="border-dashed">
            <div class="text14 mb20px">
              <span class="bold">Step 3</span><br />
              <span>
                Upload the metadata template.<br />
                Drag and drop the file here or click “Upload”.
              </span>
            </div>
            <ngx-dropzone
              [multiple]="false"
              class="drag-and-drop"
              (change)="onSelect($event)"
              [processDirectoryDrop]="true"
              [ngClass]="statusClass"
              accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              [ngStyle]="{
                'pointer-events': allFiles.length > 0 ? 'none' : 'auto',
              }"
            >
              <ngx-dropzone-label>
                <div *ngIf="!isLoading && !active">
                  <div class="mt20px">
                    <button mat-button [disabled]="active">
                      <img
                        src="../../../assets/icons/upload-f3-1.svg"
                        class="mr10px"
                      />
                      Upload
                    </button>
                  </div>
                </div>
              </ngx-dropzone-label>
              <ngx-dropzone-preview
                *ngIf="allFiles.length > 0"
                [removable]="true"
                (removed)="onRemove()"
              >
                <ngx-dropzone-label class="dropzone-label"
                  ><img
                    src="../../../assets/icons/file.svg"
                    class="img-label"
                  />{{ allFiles[0].name }} ({{
                    allFiles[0].type
                  }})</ngx-dropzone-label
                >
                <ngx-dropzone-remove-badge>
                  <button mat-icon-button (click)="onRemove()">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </ngx-dropzone-remove-badge>
              </ngx-dropzone-preview>
            </ngx-dropzone>
            <button
              mat-button
              *ngIf="allFiles.length > 0"
              (click)="downloadFile(allFiles[0])"
            >
              Download Sample Metadata
            </button>
          </div>
        </div>
        <div class="button-wrapper jc-end">
          <button
            mat-flat-button
            color="accent"
            class="text-white"
            data-attr="btn"
            [ngClass]="{ active: active === true }"
            [disabled]="allFiles.length === 0 || isButtonClicked"
            (click)="onClick(stepper)"
            type="button"
          >
            Next
            <span class="img-icon"
              ><img src="../../../assets/images/arrow-r.svg"
            /></span>
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel matStepperIcon="edit">
          <span class="label-stepper-new">Add Data</span>
        </ng-template>
        <!-- <span class="text-white bold">Import Metadata</span> -->
        <div
          class="container-row-only article-container second-container mb30px"
        >
          <div class="card-basic-new card-second-step">
            <span class="upload-files-text">Upload Files</span>
            <div class="text14 drop-raw">
              <ngx-dropzone
                class="second-dropzone"
                [multiple]="true"
                (change)="onSecondSelect($event)"
                (change)="onSecondSelect($event)"
                [accept]="'.zip,.raw'"
                [disabled]="!isDropzoneEnabled"
              >
                <ngx-dropzone-label class="dropzone-second-label">
                  <span class="drag-text-second"
                    >Drag and drop file (.raw) here or click Upload</span
                  >
                  <button mat-button>
                    <img
                      src="../../../assets/icons/upload-f3-1.svg"
                      class="mr10px drag-img"
                    />
                    <span class="upload-text">Upload</span>
                  </button>
                </ngx-dropzone-label>
                <ngx-dropzone-preview
                  *ngFor="let f of files"
                  [removable]="true"
                >
                  <ngx-dropzone-label
                    >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
                  >
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="test-h">
              <div class="content-sidenav">
                <article>
                  <div class="button-wrapper gap15 mt10px">
                    <button
                      mat-stroked-button
                      color="accent"
                      matStepperPrevious
                      (click)="clearRequests()"
                    >
                      Back
                    </button>
                    <button
                      mat-flat-button
                      color="accent"
                      matStepperNext
                      class="text-white"
                      (click)="success()"
                      [disabled]="!statusSet || isClicked"
                    >
                      Import
                      <img src="../../../../../../assets/icons/upload.svg" />
                    </button>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div class="column-spacer"></div>
          <div class="samples-table">
            <div class="table-header" *ngIf="isAllSetsLoaded">
              <span>
                Make sure your files were matched with the metadata file.
              </span>
            </div>
            <table
              *ngIf="isAllSetsLoaded"
              mat-table
              [dataSource]="dataSource.data"
              class="csv-table mat-elevation-z8"
            >
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Sample Name</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngFor="let attribute of element.attributes">
                    <ng-container *ngIf="attribute.name === 'Sample Name'">
                      {{ attribute.content }}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>File Name</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngFor="let attribute of element.attributes">
                    <ng-container *ngIf="attribute.name === 'Raw File Path'">
                      {{ getFilename(attribute.content) }}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef class="weight-column">
                  Status
                </th>
                <td mat-cell *matCellDef="let element" class="weight-column">
                  <div *ngIf="uploadErrorMap[element.attributes[0].content]">
                    <span>uploading error</span>
                    <span class="red-box" (click)="resetUploadError(element)"
                      >X</span
                    >
                  </div>
                  <div *ngIf="!uploadErrorMap[element.attributes[0].content]">
                    <div [ngSwitch]="element.status">
                      <div *ngSwitchCase="'DONE'" class="row-status">
                        <ng-container
                          *ngIf="allQCChecksPassed(element); else failedQC"
                        >
                          <img src="../../../../../assets/icons/check.svg" />
                          <span>All QC checks passed.</span>
                        </ng-container>
                        <ng-template #failedQC>
                          <img src="../../../../../assets/icons/warning.svg" />
                          <span
                            class="qc-status-hover"
                            (mouseenter)="positionPopup($event, popup)"
                          >
                            {{ numberOfQCChecksPassed(element) }}/{{
                              element.qc_checks.length
                            }}
                            QC checks passed.
                            <div #popup class="qc-popup">
                              <div class="disclaimer">
                                <img
                                  src="../../../../../assets/icons/info.svg"
                                />
                                <span
                                  >QC Checks are currently an experimental
                                  feature</span
                                >
                              </div>
                              <div class="qc-check-list">
                                <div
                                  *ngFor="let check of element.qc_checks"
                                  class="qc-check-item"
                                >
                                  <img
                                    [src]="
                                      check.test_result
                                        ? '../../../../../assets/icons/check.svg'
                                        : '../../../../../assets/icons/error-status.svg'
                                    "
                                    alt="QC check status"
                                  />
                                  <span>{{
                                    formatTestType(check.test_type)
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </span>
                        </ng-template>
                      </div>
                      <div *ngSwitchCase="'ERROR'" class="row-status">
                        <img
                          src="../../../../../assets/icons/error-status.svg"
                        /><span>{{ decodeErrorCode(element.error_code) }}</span>
                      </div>
                      <div *ngSwitchCase="'UPLOADED'" class="row-status">
                        <div class="spinner-in-process">
                          <mat-spinner></mat-spinner>
                        </div>
                        <span>Processing...</span>
                      </div>
                      <div
                        *ngSwitchCase="'CONVERTING_TO_MZML'"
                        class="row-status"
                      >
                        <div class="spinner-in-process">
                          <mat-spinner></mat-spinner>
                        </div>
                        <span>RAW File is being converted to mzML.</span>
                      </div>
                      <div
                        *ngSwitchCase="'RUNNING_QC_CHECKS'"
                        class="row-status"
                      >
                        <div class="spinner-in-process">
                          <mat-spinner></mat-spinner>
                        </div>
                        <span>Running QC checks.</span>
                      </div>
                      <div *ngSwitchDefault>
                        <div
                          *ngIf="
                            uploadProgressMap[element.attributes[0].content] !==
                              undefined &&
                            uploadProgressMap[element.attributes[0].content] !==
                              null &&
                            element.status !== 'DONE' &&
                            element.status !== 'CONVERTING_TO_MZML' &&
                            element.status !== 'ERROR'
                          "
                          class="upload-container"
                        >
                          <div class="spinner-in-process">
                            <mat-spinner></mat-spinner>
                          </div>
                          <span>Uploading...</span>
                        </div>
                        <div
                          *ngIf="
                            uploadProgressMap[element.attributes[0].content] ===
                              undefined ||
                            uploadProgressMap[element.attributes[0].content] ===
                              null
                          "
                          class="row-status"
                        >
                          <img src="../../../../../assets/icons/unknow.svg" />
                          <span *ngIf="element.error_code !== 'TIMEOUT'"
                            >RAW File has not been uploaded yet.</span
                          >
                          <span *ngIf="element.error_code === 'TIMEOUT'"
                            >Upload timed out. Please try uploading again.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div *ngIf="uploadProgressMap[element.attributes[0].content] !== undefined">
                </div> -->
                </td>
              </ng-container>
              <ng-container *ngFor="let attribute of elements">
                <ng-container
                  *ngIf="
                    attribute.name !== 'Sample Name' &&
                    attribute.name !== 'Set Name' &&
                    attribute.name !== 'Raw File Path'
                  "
                >
                  <ng-container matColumnDef="{{ attribute.name }}">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ attribute.name }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        getNestedAttributesDymamic(
                          element.attributes,
                          attribute.name
                        ) || ""
                      }}
                    </td>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef>Remove</th>
                <td mat-cell *matCellDef="let element">
                  <button
                    mat-button
                    color="warn"
                    style="font-weight: bold; font-size: 1.2em"
                    (click)="handleDelete(element)"
                  >
                    X
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsT"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsT"
                (click)="selectedRow = row"
                (click)="(this.commonService.openSidenav)"
              ></tr>
            </table>
            <div *ngIf="!isAllSetsLoaded">
              <div class="container-spinner">
                <div class="spinner">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</section>
